import React from "react";
import Banner from "../components/banner/banner";
import PartnerSection from "../components/PartnerSection/partner";
import AboutSection from "../components/aboutsection/AboutSection";
import Howtouse from "../components/Howtouse/Howtouse";
import ROI from "../components/ROISection/ROI";
import UseCases from "../components/UseCasesSection/UseCases";
import FAQ from "../components/FAQSection/FAQ";
import FooterSection from "../components/FooterSection/footer";
import SecondBanner from "../components/SecondBannerSection/SecondBanner";

export default function AgentSketch() {
  return (
    <>
      <Banner />
      <PartnerSection />
      <AboutSection />
      <Howtouse />
      <ROI />
      <UseCases />
      <FAQ />
      <SecondBanner />
      <FooterSection />
    </>
  );
}
