import React from "react";
import "./partner.css";
import metamask from "../../assets/PartnerSection/metamask-image.svg";
import bitgo from "../../assets/PartnerSection/bitgo-image.svg";
import coinbase from "../../assets/PartnerSection/coinbase-image.svg";
import trustWallet from "../../assets/PartnerSection/trust-wallet-image.svg";
import exodus from "../../assets/PartnerSection/exodus-image.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";

const PartnerSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`trusted-partners ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div className="container">
        <div className="trusted-by-partners-wrapper">
          <h2>Build Trust and Transparency with Industry Leading Partners</h2>
          <div className="row partner-logo-images">
            <img src={metamask} alt="Amazon Logo" />
            <img src={bitgo} alt="Cisco Logo" />
            <img src={coinbase} alt="At and T Logo" />
            <img src={trustWallet} alt="Comcast Logo" />
            <img src={exodus} alt="Dell Logo" />
          </div>
        </div>
      </div>
    </section>
  );
};
export default PartnerSection;
