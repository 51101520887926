import React from "react";
import "./secondbanner.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
export default function SecondBanner() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`second-banner-section ${
        isVisible ? "section-animation" : ""
      }`}
      ref={ref}
    >
      <div className="container">
        <div className="second-banner-section-wrapper">
          <div className="second-banner-section-heading">
            <h2>Unleash Your Creativity with AgentSketch </h2>
            <p>
              Start designing with Gen AI-powered tools and bring your ideas to
              life effortlessly.
            </p>
            <a href="https://demo.akira.ai/agentsketch">
              <div className="second-banner-button">
                <p>Book Demo Now</p>
              </div>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
