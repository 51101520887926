import React from "react";
import "./Howtouse.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import arrowIcon from "../../assets/BannerSection/arrow-icon.svg";
import firstCardImage from "../../assets/HowtouseSection/first-card.svg";
import secondCardImage from "../../assets/HowtouseSection/second-card.svg";
import fourthCardFirst from "../../assets/HowtouseSection/fourth-card-first.svg";
import fourthCardSecond from "../../assets/HowtouseSection/fourth-card-second.svg";
import fifthCardImage from "../../assets/HowtouseSection/fifth-card.svg";
export default function Howtouse() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [stepRef, isStepVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  const [cardRef, isCardVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <section
      className={`how-to-use ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="two"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-content-wrapper row">
            <div className="col-12 col-sm-6 col-md-6 col-lg-5 col-xl-5">
              <div className="how-to-use-description">
                <div className="section-heading">
                  <div className="heading-tag">
                    <p>How to use</p>
                  </div>
                  <h2>Effortless Design Creation with AgentSketch </h2>
                  <p>
                    Simply describe what you need, and let AgentSketch handle
                    the rest. Our Agents quickly generates stunning visuals,
                    allows you to fine-tune details, and helps bring your ideas
                    to life with ease and precision.
                  </p>
                  <div className="get-started-btn">
                    <a href="https://demo.akira.ai/agentsketch">
                      <button>
                        <p>Book Demo</p>
                        <img src={arrowIcon} alt="arrow-icon" />
                      </button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="how-to-use-cards-stepper">
                <div className="numbers-how-to-use">
                  <div className="number">1</div>
                  <div className="number">2</div>
                  <div className="number">3</div>
                </div>
                <div
                  className={`how-to-use-steps ${
                    isStepVisible ? "animate" : ""
                  }`}
                  ref={stepRef}
                >
                  <div className="step-container">
                    <h3>Define Your Goal: </h3>
                    <p>
                      Describe what you want to create in your own words—be it
                      an image, icon, or illustration.
                    </p>
                  </div>
                  <div className="step-container">
                    <h3>Let AgentSketch Work Its Magic: </h3>
                    <p>
                      Our Gen AI models take your description and instantly
                      generate a high-quality visual design.{" "}
                    </p>
                  </div>
                  <div className="step-container">
                    <h3>Customize and Refine: </h3>
                    <p>
                      Adjust colors, elements, or details as per your
                      preferences until the design meets your vision.{" "}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="how-to-use-cards-wrapper row">
            <div className="col-12 col-sm-12 col-md-8 col-lg-8 col-xl-8 first-three-card-wrapper">
              <div className="first-two-card-wrapper">
                <div className="first-card how-to-use-card">
                  <h4>Effortless Edits </h4>
                  <p>
                    Quickly resize, recolor, and reposition elements in your
                    designs with AgentSketch’s intuitive editing tools.
                  </p>
                  <img src={firstCardImage} alt="how-to-use-cards" />
                </div>
                <div className="second-card how-to-use-card">
                  <h4>Instant Modifications </h4>
                  <p>
                    Quickly modify your designs—text, images, and layout—with
                    AgentSketch, making your vision a reality in just a few
                    clicks.
                  </p>
                  <img src={secondCardImage} alt="how-to-use-cards" />
                </div>
              </div>
              <div className="third-fourth-card-wrapper">
                <div className="third-card how-to-use-card">
                  <h4> High-Speed Rendering </h4>
                  <p>
                    Our advanced rendering engine delivers results 5x faster and
                    precision 2x quicker, letting you meet tight deadlines
                    without sacrificing quality.
                  </p>
                  <div className="third-card-insight">
                    <div>
                      <h5>5x</h5>
                      <p>Faster review</p>
                      <div className="animated-line-1"></div>
                      <div className="animated-line-2"></div>
                    </div>
                    <div>
                      <h5>2x</h5>
                      <p>More precision</p>
                      <div className="animated-line-1"></div>
                      <div className="animated-line-2"></div>
                    </div>
                  </div>
                </div>
                <div className="fourth-card how-to-use-card">
                  <h4>Seamless Text Integration</h4>
                  <p>
                    Effortlessly incorporate text into your visuals with
                    AgentSketch, guaranteeing flawless alignment and style for a
                    refined final result.
                  </p>
                  <div
                    className={`fourth-card-images ${
                      isCardVisible ? "animate-card" : ""
                    }`}
                    ref={cardRef}
                  >
                    <div className="first-image">
                      <img src={fourthCardFirst} alt="how-to-use-cards" />
                    </div>
                    <div className="second-image">
                      <img src={fourthCardSecond} alt="how-to-use-cards" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-12 col-md-4 col-lg-4 col-xl-4 fifth-card-wrapper">
              <div className="fifth-card how-to-use-card">
                <h4>Versatile Plugin Support </h4>
                <p>
                  Enhance your creative process with a vast selection of
                  plugins. AgentSketch integrates the tools you need to bring
                  your ideas to life with advanced features and design elements.
                </p>
                <img src={fifthCardImage} alt="how-to-use-cards" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
