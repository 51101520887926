import React from "react";
import "./roi.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import NumberDisplay from "../NumberDisplay";
export default function ROI() {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`roi-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <p>ROI</p>
            </div>
            <h2>Discover How AgentSketch Boosts Creativity and Efficiency </h2>
            <p>
              Automates design tasks, accelerates workflows, and enhances the
              creative process while delivering high-quality results.
            </p>
          </div>
          <div className="roi-content-wrapper">
            <div className="roi-card efficiency">
              <NumberDisplay
                first={1}
                last={45}
                char="%"
                isVisible={isVisible}
              />
              <h3>Increase in Design Efficiency </h3>
            </div>
            <div className="divider"></div>
            <div className="roi-card reduction">
              <NumberDisplay
                first={100}
                last={70}
                char="%"
                isVisible={isVisible}
              />

              <h3>Faster Design Creation </h3>
            </div>
            <div className="divider"></div>

            <div className="roi-card timely-resolution">
              <NumberDisplay
                first={46}
                last={90}
                char="%"
                isVisible={isVisible}
              />

              <h3> Accuracy in Visual Output </h3>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}
