import React, { useState } from "react";
import "./FAQ.css";
import faqShow from "../../assets/FAQ/faq-show.svg";
import faqHide from "../../assets/FAQ/faq-hide.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const FAQ = () => {
  const [activeIndex, setActiveIndex] = useState(null);
  const faqData = [
    {
      question: "How does AgentSketch work? ",
      answer:
        "Simply describe your design needs in natural language, and AgentSketch will generate a professional-quality image, icon, or layout based on your input. ",
    },
    {
      question: "Can I modify the designs after they are generated?",
      answer:
        "Yes, you can fully customize the generated designs. Resize, recolor, or modify any element to suit your specific needs. ",
    },
    {
      question: "Does AgentSketch support integration with other design tools?",
      answer:
        "Absolutely! AgentSketch integrates seamlessly with your existing systems, ensuring smooth collaboration and workflow efficiency. ",
    },
    {
      question: "What types of designs can I create with AgentSketch?",
      answer:
        "You can create a wide range of designs, including icons, images, branding materials, logos, illustrations, and even interior design concepts. ",
    },
    {
      question: "Is AgentSketch suitable for professional design needs?",
      answer:
        "Yes, AgentSketch is designed for both personal and professional use, offering high-quality designs and customization options for various industries. ",
    },
    {
      question: "Can AgentSketch help with interior design?",
      answer:
        "Yes, AgentSketch can generate room layouts, color schemes, and décor ideas, helping you visualize and refine your interior design concepts.",
    },
  ];

  const toggleAccordion = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });

  return (
    <section
      className={`first-accordion ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="four"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <p>FAQ</p>
            </div>
            <h2>Frequently Asked Questions</h2>
          </div>
          <div className="accordion">
            {faqData.map((item, index) => (
              <div className="accordion-item" key={index}>
                <button
                  id={`accordion-button-${index + 1}`}
                  aria-expanded={activeIndex === index ? "true" : "false"}
                  onClick={() => toggleAccordion(index)}
                >
                  <span className="accordion-title">{item.question}</span>
                  <img
                    src={faqShow}
                    alt="Show Button"
                    className="accordion-show"
                    style={{
                      display: activeIndex === index ? "none" : "block",
                    }}
                  />
                  <img
                    src={faqHide}
                    alt="Hide Button"
                    className="accordion-hide"
                    style={{
                      display: activeIndex === index ? "block" : "none",
                    }}
                  />
                </button>
                <div className="accordion-content">
                  <p>{item.answer}</p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
};

export default FAQ;
