import React from "react";
import "./UseCases.css";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
import firstCardImage from "../../assets/UseCasesSection/first-card.svg";
import secondCardImage from "../../assets/UseCasesSection/second-card.svg";
import thirdCardFirst from "../../assets/UseCasesSection/third-card-first.svg";
import thirdCardSecond from "../../assets/UseCasesSection/third-card-second.svg";
import fourthCardFirst from "../../assets/UseCasesSection/fourth-card-first.svg";
import fourthCardSecond from "../../assets/UseCasesSection/fourth-card-second.svg";
const UseCases = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [cardRef, isCardVisible] = useIntersectionObserver({
    threshold: 0.1,
  });
  return (
    <section
      className={`use-cases-section ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="three"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-heading">
            <div className="heading-tag">
              <p>Use-cases</p>
            </div>
            <h2>Real-World Applications of AgentSketch </h2>
            <p>
              Explore how AgentSketch transforms design across multiple
              industries, from creating custom icons to visualizing interior
              design concepts.
            </p>
          </div>
        </div>
        <div
          className={`use-cases-cards-wrapper row ${
            isCardVisible ? "animate" : ""
          }`}
          ref={cardRef}
        >
          <div className="col-12 col-sm-12 col-md-7 col-lg-8 col-xl-8 first-three-card-wrapper">
            <div className="first-two-card-wrapper">
              <div className="first-card use-cases-card">
                <h3>Branding and Logo Creation </h3>
                <p>
                  Quickly create standout logos and branding materials with
                  AgentSketch.
                </p>
                <img src={firstCardImage} alt="use-cases-cards" />
              </div>
              <div className="second-card use-cases-card">
                <h3>Icon Designing </h3>
                <p>
                  Easily create custom, scalable icons with AgentSketch from
                  simple descriptions, allowing you to focus on creativity.
                </p>
                <img src={secondCardImage} alt="use-cases-cards" />
              </div>
            </div>
            <div className="third-card use-cases-card">
              <div className="third-card-left">
                <h3>Image Designing </h3>
                <p>
                  Bring your ideas to life with stunning image generation. From
                  marketing materials to illustrations, AgentSketch provides
                  professional results, helping you design with ease and
                  precision.{" "}
                </p>
                <img src={thirdCardFirst} alt="use-cases-cards" />
              </div>
              <div className="third-card-right">
                <img src={thirdCardSecond} alt="use-cases-cards" />
              </div>
            </div>
          </div>
          <div className="col-12 col-sm-12 col-md-5 col-lg-4 col-xl-4 fourth-card-wrapper">
            <div className="fourth-card use-cases-card">
              <h3>Interior Designing </h3>
              <p>
                Transform your dream space into reality with AgentSketch’s
                interior design tools for layouts, color schemes, and décor
                ideas.
              </p>
              <img src={fourthCardFirst} alt="use-cases-cards" />
              <img src={fourthCardSecond} alt="use-cases-cards" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UseCases;
