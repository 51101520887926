import React from "react";
import "./AboutSection.css";
import aboutImage from "../../assets/AboutSection/aboutImg.svg";
import featureIcon1 from "../../assets/AboutSection/feature-icon-1.svg";
import featureIcon2 from "../../assets/AboutSection/feature-icon-2.svg";
import featureIcon3 from "../../assets/AboutSection/feature-icon-3.svg";
import useIntersectionObserver from "../customHooks/useIntersectionObserver";
const AboutSection = () => {
  const [ref, isVisible] = useIntersectionObserver({ threshold: 0.1 });
  const [stepRef, isStepVisible] = useIntersectionObserver({ threshold: 0.1 });
  return (
    <section
      className={`about-us ${isVisible ? "section-animation" : ""}`}
      ref={ref}
      id="one"
    >
      <div className="container">
        <div className="section-wrapper">
          <div className="section-content-wrapper row no-gutters">
            <div className="content-left col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div className="section-heading">
                <div className="heading-tag">
                  <p>About</p>
                </div>
                <h2>Revolutionizing Design with Multi-Modal</h2>
                <p>
                  At <strong>AgentSketch</strong>, we leverage cutting-edge
                  technology to transform your ideas into stunning visual
                  designs. Our multi-model system is equipped to handle every
                  step of the design process, from generating images from
                  scratch to refining and modifying them with ease.
                </p>
              </div>
              <div
                className={`features ${isStepVisible ? "animate" : ""}`}
                ref={stepRef}
              >
                <div className="feature feature-1">
                  <div className="feature-heading">
                    <img src={featureIcon1} alt="feature list icon" />
                    <h3>Multiple AI Modes</h3>
                  </div>
                  <div className="feature-description">
                    <p>
                      Each task is handled by a dedicated Gen AI model,
                      optimized for generating images, inpainting, and modifying
                      existing designs.
                    </p>
                  </div>
                </div>
                <div className="feature feature-2">
                  <div className="feature-heading">
                    <img src={featureIcon2} alt="feature list icon" />
                    <h3>Fully Customizable Designs </h3>
                  </div>
                  <div className="feature-description">
                    <p>
                      Choose styles, color schemes, filters, and even
                      perspectives to perfectly match your vision.
                    </p>
                  </div>
                </div>
                <div className="feature feature-3">
                  <div className="feature-heading">
                    <img src={featureIcon3} alt="feature list icon" />
                    <h3>Unmatched Realism and Detail </h3>
                  </div>
                  <div className="feature-description">
                    <p>
                      Our advanced AI creates high-end textures and realistic
                      elements, delivering professional-quality designs
                      effortlessly.
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="content-right col-12 col-sm-6 col-md-6 col-lg-6 col-xl-6">
              <div
                className={`image-container ${isStepVisible ? "animate" : ""}`}
                ref={stepRef}
              >
                <img src={aboutImage} alt="About us" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
